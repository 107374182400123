/* disabled padding and removed border in month view header */
.rbc-month-view .rbc-header {
  padding: 0;
  border: 0;
}
/* made calendar month header same size as day rows and added small margin to bottom */
.rbc-month-header {
  position: relative;
  margin-bottom: 9pt;
  -webkit-flex: 1 0 0;
  -ms-flex: 1 0 0px;
  flex: 1 0 0;
  -webkit-flex-basis: 0px;
  -ms-flex-preferred-size: 0px;
  flex-basis: 0px;
  overflow: hidden;
}
/* adjusted properties of event cell in month view */
.rbc-month-view .rbc-event {
  height: 14pt;
  padding: 0px 5px;
  font-size: 13px;
  font-weight: 400;
  margin-right: 4pt;
}
/* removed border from month view */
.rbc-month-view {
  border: 0;
}
/* removed border and added little space between month view day cells */
.rbc-month-view .rbc-day-bg + .rbc-day-bg {
  border-left: 0;
  margin-left: 8pt;
}
/* added little space between month view date header cells */
.rbc-month-view .rbc-date-cell + .rbc-date-cell {
  margin-left: 8pt;
}
/* removed row borders between month view day cells */
.rbc-month-view .rbc-month-row + .rbc-month-row {
  border-top: 0;
}
/* added little space between month view header cells */
.rbc-header + .rbc-header {
  margin-left: 8pt;
}
/* changed background color of actual day in month view */
.rbc-month-view .rbc-today {
  background-color: white;
}
/* changed background color of "off-range" cell background in month view */
.rbc-month-view .rbc-off-range-bg {
  background: white;
}
/* drawing of small lines in bottom right cell corner in month view */
.rbc-month-view .rbc-day-bg {
  display: flex;
  flex-direction: column;
}
.rbc-month-view .rbc-day-bg::after {
  content: '';
  bottom: 0pt;
  height: 10pt;
  width: 10pt;
  border-bottom: 2px solid #f0f0f0;
  border-right: 2px solid #f0f0f0;
  position: absolute;
  align-self: flex-end;
}
