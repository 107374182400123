.ct-grid {
  stroke: rgba(255, 255, 255, 0.5);
  stroke-width: 1px;
  stroke-dasharray: 2px;
}

.ct-series-a .ct-point,
.ct-series-a .ct-line,
.ct-series-a .ct-bar,
.ct-series-a .ct-slice-donut {
  stroke: rgba(255, 255, 255, 1);
}

.ct-label.ct-horizontal.ct-end {
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-label {
  color: rgba(255, 255, 255, 0.7);
}

.ct-chart-line .ct-label,
.ct-chart-bar .ct-label {
  display: block;
  display: -ms-flexbox;
  display: flex;
}

.ct-label {
  fill: rgba(0, 0, 0, 0.4);
  line-height: 1;
}
.ct-point {
  stroke-width: 15px;
}

.ct-line {
  stroke-width: 2px;
}
